import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';
import { Language } from '@modules/creative-set/creative-set-models';
import { Localization } from '@shared/models/version/localization.model';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
    private localizations$: Observable<Localization[]>;

    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}

    /**
     * Get localizations
     */
    public getLocalizations(): Observable<Localization[]> {
        const accountSlug = this.sessionService.user.account.slug;
        const brandSlug = this.sessionService.user.brand.slug;

        const localizationUrl = `${AppConfig.config.origins.ACCOUNT_ACCESS_URL}/api/brand/current/${accountSlug}/${brandSlug}/localizations/`;
        if (!this.localizations$) {
            this.localizations$ = this.http.get(localizationUrl).pipe(
                map((data: any) =>
                    data.map((localization: Localization) => new Localization().deserialize(localization))
                ),
                shareReplay(1)
            );
        }

        return this.localizations$;
    }

    getLanguages(): Observable<Language[]> {
        return this.getLocalizations().pipe(
            map((localizations: Localization[]) =>
                localizations.map((localization: Localization) => ({
                    id: localization.id,
                    flag: localization.culture.cultureCode,
                    versionName: localization.name,
                    targetUrl: localization.targetUrl,
                    mainLocalization: localization.mainLocalization
                }))
            )
        );
    }
}
