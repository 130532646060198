import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BrowserState } from '@shared/models/browserState.model';

@Component({
    selector: 'not-supported',
    imports: [CommonModule],
    templateUrl: './not-supported.component.html',
    styleUrls: ['./not-supported.component.scss']
})
export class NotSupportedComponent implements OnInit {
    public isChrome: boolean;
    public isFirefox: boolean;
    public userAgent: BrowserState;

    constructor(private platform: Platform) {}

    public ngOnInit(): void {
        this.isChrome = this.platform.BLINK;
        this.isFirefox = this.platform.FIREFOX;
    }
}
