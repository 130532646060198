import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'view404',
    imports: [CommonModule, UIModule],
    templateUrl: '404.view.component.html',
    styleUrls: ['./404.view.component.scss']
})
export class View404Component implements OnInit {
    isFullPage: boolean;

    constructor(private route: ActivatedRoute) {}
    ngOnInit(): void {
        this.isFullPage = !!this.route.snapshot.data?.fullPage;
    }
}
