import { AppConfig } from '@config/app.config';
import { AppScripts } from '@plugins/scripts/app-scripts';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig).then(() => {
    AppScripts.init();
    // Set global
    (window as any).AppConfig = AppConfig.config;
});
