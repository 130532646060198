import { AppService } from './core/services/internal/app.service';
import { config as environment } from '../lib/config/environment.dev';
import { AppConfig } from '@config/app.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { proxyAuthInterceptorFn } from '@shared/interceptors/proxy-auth.interceptor';
import { getAuthConfig } from '@shared/auth0/auth0.config';
import { provideAuth0 } from '@auth0/auth0-angular';
import { UserSessionGuard } from '@shared/guards/user-session.guard';
import { ErrorHandler } from '@angular/core';
import { SignalRHubService } from '@core/services/api/signalr/signalr-hub.service';
import { TestSignalRHubService } from '@core/services/api/signalr/test-signalr-hub.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { graphqlProvider } from './graphql.provider';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { EllipsisPipe } from '@shared/pipes/ellipsis.pipe';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject, take } from 'rxjs';
import { FeatureFlagsService } from '@core/services/internal/feature-flag.service';

export const appConfig = {
    providers: [
        AppService,
        provideRouter(routes),
        provideAnimations(),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: SignalRHubService,
            useClass: CookieService.get('IN_TEST') ? TestSignalRHubService : SignalRHubService
        },
        {
            provide: FeatureFlagsService,
            useFactory: (): FeatureFlagsService => {
                const service = new FeatureFlagsService();
                service.initialize({
                    enabled: environment.FEATURE_FLAGS.enabled,
                    url: environment.FEATURE_FLAGS.url,
                    clientKey: environment.FEATURE_FLAGS.clientKey,
                    appName: 'BannerflowClient'
                });
                service.initialized$.pipe(take(1)).subscribe(() => service.start());
                return service;
            }
        },
        UserSessionGuard,
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.origins.LIST_SERVICE_URL,
                    AppConfig.config.origins.STUDIO_URL
                ])
            })
        ),
        provideNotificationService({
            apiUri: AppConfig.config.origins.NOTIFICATION_SERVICE_URL,
            accessToken$: new BehaviorSubject<string | undefined>(undefined),
            signalR: {
                enabled: AppConfig.config.NSSignalR.enabled,
                url: AppConfig.config.NSSignalR.url
            }
        }),
        graphqlProvider,
        provideHttpClient(withInterceptors([proxyAuthInterceptorFn])),
        provideAuth0(getAuthConfig()),
        EllipsisPipe
    ]
};
