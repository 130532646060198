import { Injectable } from '@angular/core';
import { distinctUntilChanged, fromEvent, map, Observable, of, shareReplay, startWith } from 'rxjs';
import { EVENTS, IConfig, UnleashClient } from 'unleash-proxy-client';

type FeatureFlags = 'NotificationService-Integrations' | 'LandingPage_CustomHostnames';
type FeatureFlagsConfig =
    | {
          enabled: false;
      }
    | ({
          enabled: true;
      } & IConfig);

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {
    private configs: FeatureFlagsConfig | undefined;

    initialized$: Observable<void>;
    update$: Observable<void>;

    private unleashClient: UnleashClient;
    private readonly defaultValues: Record<FeatureFlags, boolean> = {
        'NotificationService-Integrations': false,
        'LandingPage_CustomHostnames': false
    };

    initialize(configs: FeatureFlagsConfig): void {
        this.configs = configs;
        if (!this.configs.enabled) {
            this.initialized$ = of();
            this.update$ = of();
            return;
        }

        this.unleashClient = new UnleashClient(this.configs);
        this.initialized$ = fromEvent<void>(this.unleashClient, EVENTS.INIT).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
        );
        this.update$ = fromEvent<void>(this.unleashClient, EVENTS.UPDATE).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    start(): void {
        if (!this.configs.enabled) {
            return;
        }
        this.unleashClient.start();
    }

    setContextField(field: string, value: string): void {
        if (!this.configs.enabled) {
            return;
        }
        this.unleashClient.setContextField(field, value);
    }

    isEnabled(featureFlag: FeatureFlags): boolean {
        if (!this.configs.enabled) {
            return !!this.defaultValues[featureFlag];
        }
        return this.unleashClient.isEnabled(featureFlag);
    }

    isDisabled(featureFlag: FeatureFlags): boolean {
        return !this.isEnabled(featureFlag);
    }

    isEnabled$(featureFlag: FeatureFlags): Observable<boolean> {
        return this.update$.pipe(
            startWith(this.defaultValues[featureFlag] ?? false),
            map(() => this.isEnabled(featureFlag)),
            distinctUntilChanged()
        );
    }

    getVariant(featureFlag: FeatureFlags): boolean | string {
        if (!this.configs.enabled) {
            return this.defaultValues[featureFlag];
        }
        const variant = this.unleashClient.getVariant(featureFlag);

        if (!variant.enabled || !variant.payload) {
            return this.defaultValues[featureFlag];
        }
        return variant.payload.value;
    }

    getVariant$(featureFlag: FeatureFlags): Observable<boolean | string> {
        return this.update$.pipe(
            startWith(this.defaultValues[featureFlag]),
            map(() => this.getVariant(featureFlag)),
            distinctUntilChanged()
        );
    }

    isDisabled$(featureFlag: FeatureFlags): Observable<boolean> {
        return this.isEnabled$(featureFlag).pipe(map(flagState => !flagState));
    }
}
