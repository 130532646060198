import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { AzureAppConfigFeatureService } from '@core/services/api/azure/azure-app-config-feature.service';

@Component({
    selector: 'maintenance-mode',
    imports: [CommonModule, UIModule],
    templateUrl: './maintenance-mode.component.html',
    styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private azAppConfigService: AzureAppConfigFeatureService
    ) {}

    public async ngOnInit(): Promise<void> {
        if (this.route.snapshot.queryParamMap.has('label') && this.route.snapshot.queryParamMap.has('key')) {
            const maintenanceConfigLabel: string = this.route.snapshot.queryParamMap.get('label');
            const maintenanceConfigKey: string = this.route.snapshot.queryParamMap.get('key');

            const isMaintenance: boolean = await this.azAppConfigService.getConfigurationSetting({
                label: maintenanceConfigLabel,
                key: maintenanceConfigKey
            });

            if (!isMaintenance) {
                // this should go to the default page which b2 routes now
                window.location.href = AppConfig.config.origins.B2_URL;
            }
        }
    }
}
